import { useState } from "react";
import styled from "styled-components"
import { RiMenu3Line, RiCloseLine } from "react-icons/ri"

const Nav = () => {

    const [ToggleMenu, setToggleMenu] = useState(false);

    const StyledNav = styled.header`
        color: white;
        font-size: 1.5em;
        font-weight: 100;

        height: 80px;
        width: 100%;
        padding: 20px 10px;
        
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        z-index: 2;

        & .nav{
            display: flex;
            margin-right: 10px;
        }

        & a{
            height: inherit;
            text-decoration: none;
        }

        & .logo {
            max-height: 80%;
            display: flex;
            margin-left: 20px;
            margin-right: auto;
        }

        & .links{
            padding: 20px;

            @media screen and (max-width: 550px) {
                display: none;
            }
        }

        & .menu{
            justify-content: center;
            align-items: center;
            padding: 30px;
            display: none;
            z-index: 2;

            @media screen and (max-width: 550px) {
                display: flex;
            }

            & svg{
                cursor: pointer;
            }
        }

        & .menuLinks{
            position: absolute;
            z-index: 2;
            top: 100px;
            right: 2%;
            margin: 0px auto;

            background-color: rgb(0, 11, 39);
            height: 150px;
            width: 90%;
            padding: 20px 10px;
            border: solid grey 1px;
            border-radius: 5px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

        }

        .scale-up-center {
	        -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        }

        /* ----------------------------------------------
        * Generated by Animista on 2022-6-22 9:5:56
        * Licensed under FreeBSD License.
        * See http://animista.net/license for more info. 
        * w: http://animista.net, t: @cssanimista
        * ---------------------------------------------- */

        /**
        * ----------------------------------------
        * animation scale-up-center
        * ----------------------------------------
        */
        @-webkit-keyframes scale-up-center {
        0% {
            -webkit-transform: scale(0.5);
                    transform: scale(0.5);
        }
        100% {
            -webkit-transform: scale(1);
                    transform: scale(1);
        }
        }
        @keyframes scale-up-center {
        0% {
            -webkit-transform: scale(0.5);
                    transform: scale(0.5);
        }
        100% {
            -webkit-transform: scale(1);
                    transform: scale(1);
        }
        }

    `;


    return(
        <StyledNav id="nav">
            <a href="/">
                <img src="/A.webp" alt="A" className="logo"/>
            </a>
            <nav className="nav">
                <div className="links"><a href="#projects">Projects</a></div>
                <div className="links"><a href="#technologies">Technologies</a></div>
                <div className="links"><a href="#contact">Contact</a></div>
                <div className="menu">
                    {ToggleMenu 
                    ? <div><RiCloseLine size={35} onClick={() => setToggleMenu(false)}/></div>
                    : <div><RiMenu3Line size={30} onClick={() => setToggleMenu(true)}/></div>
                    }
                    {ToggleMenu && (
                        <div className="menuLinks scale-up-center">
                            <div onClick={() => setToggleMenu(false)}><a href="#projects">Projects</a></div>
                            <div onClick={() => setToggleMenu(false)}><a href="#technologies">Technologies</a></div>
                            <div onClick={() => setToggleMenu(false)}><a href="#contact">Contact</a></div>
                        </div>
                    )}
                </div>
            </nav>
        
        </StyledNav>
    )
}

export default Nav